import React, { useState } from 'react'
import DummyHero from '../../components/dummy-hero';
import Meta from '../../components/meta';
import Layout from '../../components/layout';
import logo from '../../images/favicon.png';
import { BiChevronRightCircle } from "react-icons/bi";
import Checkbox from '../../components/checkbox'
import { TextField } from '../../components/text-field';
import { Formik, Form } from 'formik'; // Form validation
import * as Yup from 'yup'; // Form validation
import { ToastContainer, toast } from 'react-toastify'; // toastify
import 'react-toastify/dist/ReactToastify.css'; // toastify
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import './index.css';
import { navigate } from 'gatsby';

function ContactUs() {

  const { executeRecaptcha } = useGoogleReCaptcha();
  // const [token, setToken] = useState('');
  const baseUrl = process.env.STRAPI_API_URL ?? "https://strapi-admin.digitallaboratory.net";

 
  const [loading, setLoading] = useState(false);

  // validations
  const validate = Yup.object({
    firstName: Yup.string()
      .required('First Name is required'),
    lastName: Yup.string()
      .required('Last Name is required'),
    subject: Yup.string()
      .required('Subject is required'),
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
    companyName: Yup.string()
      .required('Company Name is required'),

    message: Yup.string()
      .required('Message is required'),
    termsAndConditions: Yup
      .bool()
      .oneOf([true], 'You need to accept the privacy policy'),
  });

  // contact form submit
  const contactSubmit = async (data) => {
    if (loading)
      return

    setLoading(true);

    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return
    }

    const result = await executeRecaptcha('contact')

    // setToken(result);

    const formData = JSON.stringify({ data, token: result })
    console.log('formData: ', formData);


    data = "";

    const url = baseUrl + "/api/contact/submit";
    let ok = false;
    try {

      const submitResult = await fetch(
        url,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: formData
        }
      );
      ok = submitResult.ok;

    } catch (error) {
      console.error(error);
    }
    if (ok) {
      navigate('/contact-us-thank-you/');
    } else {
      toast.error("Something went wrong", {
        pauseOnHover: true
      });
    }


    setLoading(false);


  }

  return (
    <Layout>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    
      <DummyHero title="GET IN TOUCH" />
      <div className='contactWrapper'>
        <div className='contactWrapper__container'>

          <div className='contactWrapper__container__heading'>
            <p>Thank you for your interest.<br />
              Drop your inquiry to learn more
              about our services.</p>
          </div>

          <div className=''>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                subject: '',
                email: '',
                companyName: '',
                departmentName: '',
                message: '',
                termsAndConditions: false
              }}
              validationSchema={validate}
              onSubmit={contactSubmit}
            >
              {
                formik => (
                  <Form >
                    <div className='contactWrapper__container__form'>
                      {/* Empty DIV */}
                      <div></div>
                      <TextField
                        label="First Name"
                        name="firstName"
                        placeholder="First Name *"
                        type="text"
                      />

                      <TextField
                        label="Last Name"
                        name="lastName"
                        placeholder="Last Name *"
                        type="text"
                      />

                      <TextField
                        label="Subject"
                        name="subject"
                        placeholder="Subject *"
                        type="text"
                      />

                      <TextField
                        label="Email"
                        name="email"
                        placeholder="Email *"
                        type="email"
                      />

                      <TextField
                        label="Company Name"
                        name="companyName"
                        placeholder="Company Name *"
                        type="text"
                      />

                      <TextField
                        label="Department Name"
                        name="departmentName"
                        placeholder="Department Name"
                        type="text"
                      />

                      <TextField
                        label="Message"
                        name="message"
                        placeholder="Message *"
                        variant="textarea"
                        type="text"
                      />
                      {/* Empty Div */}
                      <div></div>
                    </div>

                    <Checkbox name="termsAndConditions">I have read and agree to the  <a href='/privacypolicy/' target="_blank">Privacy Policy</a>.</Checkbox>

                    <div className='contactWrapper__container__form__button'>
                      <button
                        type='submit'
                      >
                        {
                          loading ? 'Submitting...' : 'SUBMIT'
                        }
                        <BiChevronRightCircle className='chev_icon' />
                      </button>
                    </div>

                  </Form>
                )
              }
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  )
}


export const Head = () => {
  // console.log("blogs: ", blogs);
  let meta = {
    title: "Contact Us",
    description: "Get In Touch Thank you for your interest. Drop your inquiry to learn more about our services. Submit",
    url: "https://www.digitallaboratory.net/contact-us/",
    image: logo
  };

  return (
    <Meta meta={meta} />
  );
};


export default ContactUs